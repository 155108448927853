exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ca-404-js": () => import("./../../../src/pages/ca/404.js" /* webpackChunkName: "component---src-pages-ca-404-js" */),
  "component---src-pages-ca-about-js": () => import("./../../../src/pages/ca/about.js" /* webpackChunkName: "component---src-pages-ca-about-js" */),
  "component---src-pages-ca-approach-js": () => import("./../../../src/pages/ca/approach.js" /* webpackChunkName: "component---src-pages-ca-approach-js" */),
  "component---src-pages-ca-contact-js": () => import("./../../../src/pages/ca/contact.js" /* webpackChunkName: "component---src-pages-ca-contact-js" */),
  "component---src-pages-ca-index-js": () => import("./../../../src/pages/ca/index.js" /* webpackChunkName: "component---src-pages-ca-index-js" */),
  "component---src-pages-ca-privacy-policy-js": () => import("./../../../src/pages/ca/privacy-policy.js" /* webpackChunkName: "component---src-pages-ca-privacy-policy-js" */),
  "component---src-pages-ca-services-js": () => import("./../../../src/pages/ca/services.js" /* webpackChunkName: "component---src-pages-ca-services-js" */),
  "component---src-pages-ca-terms-of-service-js": () => import("./../../../src/pages/ca/terms-of-service.js" /* webpackChunkName: "component---src-pages-ca-terms-of-service-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-approach-js": () => import("./../../../src/pages/en/approach.js" /* webpackChunkName: "component---src-pages-en-approach-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-terms-of-service-js": () => import("./../../../src/pages/en/terms-of-service.js" /* webpackChunkName: "component---src-pages-en-terms-of-service-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-about-js": () => import("./../../../src/pages/es/about.js" /* webpackChunkName: "component---src-pages-es-about-js" */),
  "component---src-pages-es-approach-js": () => import("./../../../src/pages/es/approach.js" /* webpackChunkName: "component---src-pages-es-approach-js" */),
  "component---src-pages-es-contact-js": () => import("./../../../src/pages/es/contact.js" /* webpackChunkName: "component---src-pages-es-contact-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-privacy-policy-js": () => import("./../../../src/pages/es/privacy-policy.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-js" */),
  "component---src-pages-es-services-js": () => import("./../../../src/pages/es/services.js" /* webpackChunkName: "component---src-pages-es-services-js" */),
  "component---src-pages-es-terms-of-service-js": () => import("./../../../src/pages/es/terms-of-service.js" /* webpackChunkName: "component---src-pages-es-terms-of-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-404-js": () => import("./../../../src/pages/it/404.js" /* webpackChunkName: "component---src-pages-it-404-js" */),
  "component---src-pages-it-about-js": () => import("./../../../src/pages/it/about.js" /* webpackChunkName: "component---src-pages-it-about-js" */),
  "component---src-pages-it-approach-js": () => import("./../../../src/pages/it/approach.js" /* webpackChunkName: "component---src-pages-it-approach-js" */),
  "component---src-pages-it-contact-js": () => import("./../../../src/pages/it/contact.js" /* webpackChunkName: "component---src-pages-it-contact-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-it-privacy-policy-js": () => import("./../../../src/pages/it/privacy-policy.js" /* webpackChunkName: "component---src-pages-it-privacy-policy-js" */),
  "component---src-pages-it-services-js": () => import("./../../../src/pages/it/services.js" /* webpackChunkName: "component---src-pages-it-services-js" */),
  "component---src-pages-it-terms-of-service-js": () => import("./../../../src/pages/it/terms-of-service.js" /* webpackChunkName: "component---src-pages-it-terms-of-service-js" */)
}

